import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';
import { isNil } from '@/shared/utils';

export const computeBudget = async (projectId = null) => {
	try {
		const RECOMPUTE_BUDGET = gql`
			query {
				RecomputeBudget(ProjectId: null)
			}
		`;

		const {
			data: { GetBudgetDataList }
		} = await apollo.query({
			query: RECOMPUTE_BUDGET,
			fetchPolicy: 'no-cache'
		});

		return GetBudgetDataList;
	} catch (error) {
		console.log(error);
	}
};

export const getProjectUserSalaries = async (filter = {}) => {
	try {
		let QUERY_GET_PROJECT_USER_SALARIES = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserSalaries(Filter: $filter) {
					id
					status
					startDate
					endDate
					fullName
					departmentName
					functionName
					hours
					salary
					co2
					hoursStr
					departmentValue
					category
					categoryName
					categoryCostCenter
					timesheet {
						id
						processed
					}
				}
			}
		`;

		const {
			data: { GetProjectUserSalaries }
		} = await apollo.query({
			query: QUERY_GET_PROJECT_USER_SALARIES,
			variables: {
				filter
			}
		});

		return GetProjectUserSalaries;
	} catch (error) {
		console.log(error);
	}
};

export const getProjectUserExpenseDetail = async (filter = {}) => {
	try {
		let QUERY_GET_PROJECT_USER_EXPENSE_DETAIL = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserExpenseDetail(Filter: $filter) {
					expenseItem {
						category
						paiementType
						supplierName
						supplierId
						type
						department
						departmentName
						userDepartmentName
						description
						subCategory
						date
						id
						images
						amountTotal
						amountVat
						comment
						km
						kgCoTwo
						fromLocation
						toLocation
						validated
						categoryName
						functionName
						paiementTypeName
						costCenter
						encoderId
						function
						carbon {
							id
						}
						processed
						encoder {
							id
							name
							email
							firstName
							fullName
						}
						user {
							id
							name
							firstName
						}
					}
				}
			}
		`;

		const {
			data: { GetProjectUserExpenseDetail }
		} = await apollo.query({
			query: QUERY_GET_PROJECT_USER_EXPENSE_DETAIL,
			variables: {
				filter
			}
		});

		return GetProjectUserExpenseDetail;
	} catch (error) {
		console.log(error);
	}
};

export const getProjectUserPoDetail = async (filter = {}, fetchPolicy = 'cache-first') => {
	try {
		let QUERY_GET_PROJECT_USER_PO_DETAIL = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserPoDetail(Filter: $filter) {
					purchaseOrder {
						id
						userProjectId
						encoderId
						poReference
						description
						department
						supplierId
						consumed
						requestDate
						deliveryDate
						deliveryAddress
						invoicedToProduction
						validated
						laValidatedId
						laValidatedWhen
						lbValidatedId
						lbValidatedWhen
						amount
						amountVat
						amountTotal
						information
						comment
						notDetailed
						departmentName
						supplierName
						function
						functionName
						expenseTotal
						validatedMsg
						projectTitle
						projectIdEx
						isInContract
						images
						itemCategory
						processed
						user {
							id
							name
							firstName
						}
						encoder {
							id
							name
							firstName
						}
					}
				}
			}
		`;

		const {
			data: { GetProjectUserPoDetail }
		} = await apollo.query({
			query: QUERY_GET_PROJECT_USER_PO_DETAIL,
			variables: {
				filter
			},
			fetchPolicy: 'no-cache'
		});

		return GetProjectUserPoDetail;
	} catch (error) {
		console.log(error);
	}
};

export const assignDocumentPackageToUser = async (documentId = 0, userId = 0, ContractId = null, oldDocId = null) => {
	try {
		const mutation = gql`
			mutation ($documentId: ID!, $userId: ID!, $ContractId: ID!, $oldDocId: ID) {
				AssignOnBoardingPackage(DocumentId: $documentId, UserId: $userId, ContractId: $ContractId, OldDocID: $oldDocId)
			}
		`;

		let variables = {
			documentId: parseInt(documentId, 10),
			userId: parseInt(userId, 10),
			ContractId: parseInt(ContractId, 10)
		};

		if (!isNil(oldDocId) && !isNaN(oldDocId)) {
			variables = {
				...variables,
				oldDocId: parseInt(oldDocId, 10)
			};
		}

		const {
			data: { AssignOnBoardingPackage }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return AssignOnBoardingPackage;
	} catch (e) {
		console.error({ e });
	}
};

export const getBudgetHierarchicalCategoryJson = async (
	department = 0,
	useCategoryId = false,
	contentType = null,
	co2Category = -1,
	UseAppChoice = false,
	IsGrouping = false
) => {
	try {
		// contentType => -1 (all), 0 (expense), 1 (salary)
		const query = gql`
			query ($department: ID!, $useCategoryId: Boolean!, $contentType: ID, $co2Category: ID, $UseAppChoice: Boolean, $IsGrouping: Boolean) {
				GetBudgetDetailHierarchicalListAsJsonString(
					Department: $department
					UseCategoryId: $useCategoryId
					ContentType: $contentType
					Co2Category: $co2Category
					UseAppChoice: $UseAppChoice
					IsGrouping: $IsGrouping
				)
			}
		`;

		const {
			data: { GetBudgetDetailHierarchicalListAsJsonString }
		} = await apollo.query({
			query,
			variables: {
				department: parseInt(department, 10),
				useCategoryId,
				contentType,
				co2Category,
				UseAppChoice,
				IsGrouping
			},
			fetchPolicy: 'no-cache'
		});

		return JSON.parse(GetBudgetDetailHierarchicalListAsJsonString);
	} catch (error) {
		// console.log(error)
	}
};

export const getBudgetHierarchicalAsJSON = async (startDate, endDate, departmentId = -1, forceCompute = false) => {
	try {
		let QUERY_GET_BUDGET_HIERARCHICAL_AS_JSON = null;
		let variables = null;

		if (!_.isNil(departmentId)) {
			QUERY_GET_BUDGET_HIERARCHICAL_AS_JSON = gql`
				query ($startDate: String, $endDate: String, $departmentId: ID, $forceCompute: Boolean) {
					GetBudgetDataHierarchicalListAsJsonString(
						DepartmentNumber: $departmentId
						StrTime: $startDate
						EndTime: $endDate
						ForceCompute: $forceCompute
					)
				}
			`;

			variables = {
				startDate,
				endDate,
				departmentId: parseInt(departmentId),
				forceCompute
			};
		} else {
			QUERY_GET_BUDGET_HIERARCHICAL_AS_JSON = gql`
				query ($startDate: String, $endDate: String, $forceCompute: Boolean) {
					GetBudgetDataHierarchicalListAsJsonString(StrTime: $startDate, EndTime: $endDate, ForceCompute: $forceCompute)
				}
			`;

			variables = {
				startDate,
				endDate,
				forceCompute
			};
		}

		const {
			data: { GetBudgetDataHierarchicalListAsJsonString }
		} = await apollo.query({
			query: QUERY_GET_BUDGET_HIERARCHICAL_AS_JSON,
			variables
		});

		return GetBudgetDataHierarchicalListAsJsonString;
	} catch (error) {
		console.log(error);
	}
};

export const getTotalRecordsBudgetSalaries = async (filter = {}) => {
	try {
		const QUERY_GET_TOTAL_RECORDS_BUDGET_SALARIES = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserSalariesTotalRecords(Filter: $filter)
			}
		`;

		const {
			data: { GetProjectUserSalariesTotalRecords }
		} = await apollo.query({
			query: QUERY_GET_TOTAL_RECORDS_BUDGET_SALARIES,
			variables: {
				filter
			}
		});

		return GetProjectUserSalariesTotalRecords;
	} catch (error) {
		console.log(error);
	}
};

export const getTotalRecordsBudgetExpenses = async (filter = {}) => {
	try {
		const QUERY_GET_TOTAL_RECORDS_BUDGET_EXPENSES = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserExpenseDetailTotalRecords(Filter: $filter)
			}
		`;

		const {
			data: { GetProjectUserExpenseDetailTotalRecords }
		} = await apollo.query({
			query: QUERY_GET_TOTAL_RECORDS_BUDGET_EXPENSES,
			variables: {
				filter
			}
		});

		return GetProjectUserExpenseDetailTotalRecords;
	} catch (error) {
		console.log(error);
	}
};

export const getTotalRecordsBudgetPO = async (filter = {}) => {
	try {
		const QUERY_GET_TOTAL_RECORDS_BUDGET_PO = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserPoDetailTotalRecords(Filter: $filter)
			}
		`;

		const {
			data: { GetProjectUserPoDetailTotalRecords }
		} = await apollo.query({
			query: QUERY_GET_TOTAL_RECORDS_BUDGET_PO,
			variables: {
				filter
			}
		});

		return GetProjectUserPoDetailTotalRecords;
	} catch (error) {
		console.log(error);
	}
};

export const getProjectUserExpenseSheet = async (filter = {}) => {
	try {
		const QUERY_GET_PROJECT_USER_EXPENSE_SHEET = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserExpenseSheet(Filter: $filter) {
					expense {
						id
						createdAt
						updatedAt
						userProjectId
						encoderId
						description
						date
						type
						department
						poId
						supplierId
						validated
						laValidatedId
						laValidatedWhen
						lbValidatedId
						lbValidatedWhen
						amountVat
						amount
						amountTotal
						comment
						kgCoTwo
						poReference
						departmentName
						supplierName
						function
						functionName
						validationStatus
						userDepartment
						userDepartmentName
						kmRate
						typeMsg
						processed
						user {
							name
							firstName
						}
						encoderId
						encoder {
							id
							name
							fullName
							firstName
						}
					}
				}
			}
		`;

		const {
			data: { GetProjectUserExpenseSheet }
		} = await apollo.query({
			query: QUERY_GET_PROJECT_USER_EXPENSE_SHEET,
			variables: {
				filter
			}
		});
		return GetProjectUserExpenseSheet;
	} catch (e) {
		console.log(e);
	}
};

export const getTotalRecordsExpenseSheet = async (filter = {}) => {
	try {
		const QUERY_GET_TOTAL_RECORDS_EXPENSE_SHEET = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectUserExpenseSheetTotalRecords(Filter: $filter)
			}
		`;

		const {
			data: { GetProjectUserExpenseSheetTotalRecords }
		} = await apollo.query({
			query: QUERY_GET_TOTAL_RECORDS_EXPENSE_SHEET,
			variables: {
				filter
			}
		});

		return GetProjectUserExpenseSheetTotalRecords;
	} catch (error) {
		console.log(error);
	}
};

export const SendPurchaseOrdersSlice = async (PurchaseOrderId) => {
	try {
		const mutation = gql`
			mutation ($PurchaseOrderId: [ID]!) {
				SendPurchaseOrdersSlice(PurchaseOrderId: $PurchaseOrderId)
			}
		`;

		let variables = {
			PurchaseOrderId
		};

		const {
			data: { SendPurchaseOrdersSlice }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return SendPurchaseOrdersSlice;
	} catch (e) {
		console.error({ e });
	}
};

/**
 *
 * @param idOfComponent
 * @param type (0: Salaries, 1: Expense sheet, 2: Expense, 3: PO)
 * @param valueOfProcessToChange
 * @returns {Promise<void>}
 */
export const changeStatusOfProcess = async (idOfComponent, type, valueOfProcessToChange) => {
	try {
		const mutation = gql`
			mutation ($idOfComponent: ID!, $type: Int!, $valueOfProcessToChange: Boolean!) {
				ChangeStatusOfProcess(IdOfComponent: $idOfComponent, Type: $type, ValueOfProcessToChange: $valueOfProcessToChange)
			}
		`;

		const {
			data: { ChangeStatusOfProcess }
		} = await apollo.mutate({
			mutation,
			variables: {
				idOfComponent: +idOfComponent,
				type,
				valueOfProcessToChange
			}
		});

		return ChangeStatusOfProcess;
	} catch (e) {
		console.log({ ERROR_CHANGE_STATUS_OF_PROCESS: e });
	}
};
